import React from "react";
import Icon1 from "../../../Assets/Icons/SoftwareDevelopment/1.svg";
import Icon2 from "../../../Assets/Icons/SoftwareDevelopment/2.svg";
import Icon3 from "../../../Assets/Icons/SoftwareDevelopment/3.svg";
import Icon4 from "../../../Assets/Icons/SoftwareDevelopment/4.svg";
import Icon5 from "../../../Assets/Icons/SoftwareDevelopment/5.svg";
import Icon6 from "../../../Assets/Icons/SoftwareDevelopment/6.svg";
import { Fade } from "react-awesome-reveal";

const arr = [
  {
    icon: Icon1,
    title: "Expertise in Various Technologies",
    description:
      "Our team of experienced developers specializes in a wide range of technologies, including web development, mobile app development, blockchain development, and AI development. Whatever your project requirements, we have the expertise to deliver high-quality solutions.",
  },
  {
    icon: Icon2,
    title: "Customized Solutions",
    description:
      "We understand that every business is unique, and we tailor our services to meet your specific needs. Whether you need a custom web application, a mobile app, or a blockchain solution, we work closely with you to ensure that the end product aligns with your goals and objectives.",
  },
  {
    icon: Icon3,
    title: "Scalable and Innovative Solutions",
    description:
      "We are committed to building scalable and innovative software solutions that drive growth and deliver value. Our team leverages industry-leading practices and cutting-edge technologies to ensure that your applications are future-proof and can adapt to changing business needs.",
  },
  {
    icon: Icon4,
    title: "Comprehensive Development Services",
    description:
      "From full-cycle product development to ongoing maintenance and support, we provide a wide range of services to support you throughout every stage of your project. Whether you need help with initial ideation, development, or postlaunch support, we’ve got you covered.",
  },
  {
    icon: Icon5,
    title: "Commitment to Quality",
    description:
      "We are dedicated to delivering highquality software solutions that meet the highest standards. Our team follows best practices in software development, conducts thorough testing, and ensures that your applications are secure, reliable, and performant.",
  },
  {
    icon: Icon6,
    title: "Customer Satisfaction",
    description:
      "Your satisfaction is our top priority. We strive to build long-term partnerships with our clients by providing exceptional customer service, transparent communication, and timely project delivery. We work closely with you to understand your",
  },
];

const SoftwareDevelopment = () => {
  // Your component code goes here
  return (
    <>
      <div className="bg-grey">
        <div className="container">
          <div className="row  py-5">
            <div className="col-12">
              <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
                <div className="text-center">
                  <p className="text f600 mb-2 clr-secondary">
                    SOFTWARE SOLUTIONS ON DEMAND
                  </p>
                  <div style={{ maxWidth: 430 }} className="margin-auto">
                    <p className="text-title">
                      Why choose us as your software development partner?
                    </p>
                  </div>
                  <div style={{ maxWidth: 640 }} className="margin-auto pb-2">
                    <p className="text">
                      We help you turn your business concepts into reality with
                      our expertise, innovation, and commitment to excellence.
                    </p>
                  </div>
                </div>
              </Fade>
              <div className="row">
                {arr.map((item: any, index: number) => {
                  return (
                    <div
                      className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"
                      key={index}
                    >
                      <Fade
                        triggerOnce
                        direction="up"
                        cascade={true}
                        damping={0.2}
                      >
                        <div className="px-4 py-2 d-sm-flex d-md-block d-flex">
                          <div className="m-r-20 mb-2">
                            <img src={item.icon} />
                          </div>
                          <div>
                            <Fade
                              triggerOnce
                              direction="up"
                              cascade={true}
                              damping={0.2}
                            >
                              <p className="text f600 mb-2">{item.title}</p>
                              <p className="text mb-4">{item.description}</p>
                            </Fade>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftwareDevelopment;
