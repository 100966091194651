import React from "react";
import classnames from "classnames";
import { Fade } from "react-awesome-reveal";

interface Props {
  type?: string;
}

const BlockchainDevelopment = ({ type = "tab" }: Props) => {
  // Your component code goes here
  return (
    <>
      <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
        <div
          className={classnames({
            "text-center": type === "collapse",
            "ps-5": type !== "collapse",
          })}
        >
          <p className="text f600 clr-secondary mb-2">Blockchain</p>
          <p className="text-title mb-4">
            Blockchain development services to help businesses harness the power
            of distributed ledger technology.
          </p>
          <p className="text mb-5">
            Our team of experienced developers specializes in building secure
            and scalable blockchain solutions that revolutionize industries and
            drive digital transformation.
          </p>
          <p className="text mb-5">
            Our blockchain development services include building decentralized
            applications that offer enhanced security, privacy, and user
            empowerment. Whether you’re looking to build a DApp for finance,
            supply chain, or any other industry, we have the expertise to bring
            your vision to life.
          </p>
        </div>
      </Fade>
    </>
  );
};

export default BlockchainDevelopment;
