import React from "react";
// import BgImg from "../../../Assets/Background/primary-bg.svg";
// import BgImg from "../../../Assets/Background/bubble.svg";
import BgImg from "../../../Assets/Background/wave.svg";
import LogoTitle from "../../../Assets/Logos/logo-title.svg";

import { Fade } from "react-awesome-reveal";

const Home = () => {
  // Your component code goes here
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${BgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
        }}
      >
        {/* <video
          muted
          loop
          autoPlay
          // controls
          src="https://itsstorage.sgp1.digitaloceanspaces.com/assets/Waves.webm"
          // src="https://itsstorage.sgp1.digitaloceanspaces.com/assets/Network.webm"
          style={{ width: "100%" }}
        /> */}

        <div
          className="h-100 d-flex align-items-center justify-content-center"
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        >
          <div className="container text-center">
            <Fade direction="up" cascade={true} damping={0.2}>
              <div className="mb-5">
                <img
                  src={LogoTitle}
                  style={{ maxHeight: 200, maxWidth: 300 }}
                />
              </div>
              <div className="margin-auto" style={{ maxWidth: 1000 }}>
                <p className="text-title mb-5 fs-extra-large">
                  We help build scalable and innovative software solutions that
                  accelerate startup business
                </p>
              </div>
              <div className="margin-auto" style={{ maxWidth: 780 }}>
                <p className="text mb-0">
                  We help technology-first companies speed up the delivery of
                  value by implementing industry-leading development practices
                  and equipping in-house teams with the necessary skills to
                  overcome obstacles.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
