import React from "react";
import classnames from "classnames";
import { Fade } from "react-awesome-reveal";

interface Props {
  type?: string;
}

const WebDevelopment = ({ type = "tab" }: Props) => {
  // Your component code goes here
  return (
    <>
      <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
        <div
          className={classnames({
            "text-center": type === "collapse",
            "ps-5": type !== "collapse",
          })}
        >
          <p className="text f600 clr-secondary mb-2">
            Website, Web Application, PWA, CRM & ECommerce.
          </p>
          <p className="text-title mb-4">
            we offer comprehensive web development services to help you create
            powerful and impactful online experiences.
          </p>
          <p className="text mb-5">
            Our full-stack developers combine expertise in both frontend and
            back-end technologies to create robust and dynamic web applications.
            From designing user-friendly interfaces to implementing complex
            functionalities, we ensure that your web application delivers a
            seamless user experience.
          </p>
          <p className="text mb-5">
            We specialize in creating SEO-driven applications that optimize
            website performance, ranking, and crossplatform compatibility,
            ranging from simple websites to advanced web applications.
          </p>
        </div>
      </Fade>
    </>
  );
};

export default WebDevelopment;
