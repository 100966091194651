import React from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "./App.css";
import LandingPage from "./Modules/LandingPage/LandingPage";
import ContactUs from "./Modules/ContactUs/ContactUs";
import NotFound from "./Components/NotFound/NotFound";

function App() {
  return (
    <div className="">
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/contact" component={ContactUs} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
