import React from "react";

import Logo from "../../../Assets/Logos/logo.svg";
import IconCall from "../../../Assets/Icons/Address/icon-call.svg";
import IconEmail from "../../../Assets/Icons/Address/icon-email.svg";
import IconLocation from "../../../Assets/Icons/Address/icon-location.svg";
import { Fade } from "react-awesome-reveal";

// interface Props {
//   // Define the props your component accepts
// }

const Home = () => {
  // Your component code goes here
  return (
    <>
      <div className="row">
        <div className="col-lg-1 col-md-1 col-sm-1"></div>
        <div className="col-lg-10 col-md-10 col-sm-10 col-12">
          <div className="py-5">
            <a href="/">
              <img src={Logo} className="app-logo" />
            </a>
          </div>
          <div className="mb-4">
            <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
              <p className="text-title mb-4">Contact Us</p>
              <div className="d-flex align-items-start mb-4">
                <div className="">
                  <img src={IconLocation} style={{ width: 30 }} />
                </div>
                <div className="ms-3">
                  <p className="text mb-2">WISMA MCIS ZURICH,</p>
                  <p className="text mb-2">Tower 1, Level 3A, Jalan Barat,</p>
                  <p className="text mb-0">46200, Petaling Jaya, Selangor</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <div className="">
                  <img src={IconCall} style={{ width: 30 }} />
                </div>
                <div className="ms-3">
                  <a className="text mb-0" href="tel:+60176845127">
                    +60 176845127
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center mb-5">
                <div className="">
                  <img src={IconEmail} style={{ width: 30 }} />
                </div>
                <div className="ms-3">
                  <a className="text mb-0" href="mailto:tech@addvaluez.com">
                    tech@addvaluez.com
                  </a>
                </div>
              </div>
            </Fade>
            <div className="pb-4">
              <div className="map-area">
                <div id="googleMapa">
                  <iframe
                    src="https://maps.google.com/maps?q=WISMA%20MCIS%20ZURICH,%20Jalan%20Barat,%2046200,%20Petaling%20Jaya,%20Selangor&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-1"></div>
      </div>
    </>
  );
};

export default Home;
