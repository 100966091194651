import React from "react";
import ContactWrapper from "../../Components/Wrapper/ContactWrapper";
import Home from "./Home/Home";
import ContactForm from "./ContactForm/ContactForm";

// interface Props {
//   // Define the props your component accepts
// }

const ContactUs = () => {
  // Your component code goes here
  return (
    <>
      <ContactWrapper>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <Home />
          </div>
          <div className="col-lg-6 col-md-12 col-12 bg-grey">
            <ContactForm />
          </div>
        </div>
      </ContactWrapper>
    </>
  );
};

export default ContactUs;
