import React from "react";
import classnames from "classnames";
import { Fade } from "react-awesome-reveal";

interface Props {
  type?: string;
}

const CloudDevopsDevelopment = ({ type = "tab" }: Props) => {
  // Your component code goes here
  return (
    <>
      <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
        <div
          className={classnames({
            "text-center": type === "collapse",
            "ps-5": type !== "collapse",
          })}
        >
          <p className="text f600 clr-secondary mb-2">Cloud & DevOps</p>
          <p className="text-title mb-4">
            We help businesses streamline their development processes with cloud
            technologies and DevOps practices
          </p>
          <p className="text mb-5">
            Moving your infrastructure to the cloud can provide numerous
            benefits, including cost savings, scalability, and increased
            flexibility. Our Cloud & DevOps team can help you develop a
            comprehensive cloud migration strategy, assess your existing
            infrastructure, and guide you through the migration process to
            ensure a smooth transition.
          </p>
          <p className="text mb-5">
            Our Cloud & DevOps engineers can help you set up and optimize CI/CD
            pipelines using tools like Jenkins, GitLab CI/CD, or AWS
            CodePipeline. This allows for automated testing, seamless
            integration, and efficient deployment of your applications.
          </p>
        </div>
      </Fade>
    </>
  );
};

export default CloudDevopsDevelopment;
