import React from "react";
import Client1 from "../../../Assets/Images/Client/1.png";
import Client2 from "../../../Assets/Images/Client/2.png";
import Client3 from "../../../Assets/Images/Client/3.png";
import Client4 from "../../../Assets/Images/Client/4.png";
import Client5 from "../../../Assets/Images/Client/5.png";
import { Fade } from "react-awesome-reveal";

// interface Props {
//   // Define the props your component accepts
// }

const arr = [
  { img: Client1 },
  { img: Client2 },
  { img: Client3 },
  { img: Client4 },
  { img: Client5 },
];

const Clients = () => {
  // Your component code goes here
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-1 col-sm-12"></div>
          <div className="col-md-10 px-0 py-5 col-sm-12">
            <div className="text-center">
              <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
                <p className="text mb-2 clr-secondary f600">CLIENTS</p>
                <p className="text-title mb-4">All our clients love our work</p>
                <div className="d-md-flex d-sm-inline-flex justify-content-around pt-4">
                  {arr.map((item: any, index: number) => {
                    return (
                      <img
                        src={item.img}
                        key={index}
                        className="mb-4"
                        style={{ height: 60, maxWidth: "100%" }}
                      />
                    );
                  })}
                </div>
              </Fade>
            </div>
          </div>
          <div className="col-md-1 col-sm-12"></div>
        </div>
      </div>
    </>
  );
};

export default Clients;
