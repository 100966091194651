import React from "react";
import LogoCircle from "../../Assets/Logos/logo-circle.svg";
import { Divider } from "antd";
import { Fade } from "react-awesome-reveal";

// interface Props {
//   // Define the props your component accepts
// }

const Footer = () => {
  // Your component code goes here
  return (
    <>
      <div className="bg-primary py-5 px-5" style={{ textAlign: "left" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-12 col-sm-12 col-12">
              <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
                <div className="row">
                  <div className="col-md-8 col-sm-12 col-12 mb-4">
                    <img src={LogoCircle} className="mb-4" />
                    <p className="text-medium mb-2 clr-white">
                      Addvaluez Sdn Bhd (1510887-A)
                    </p>
                    <p className="text mb-0 clr-white">
                      Transforming Ideas into Powerful Software Solutions
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12 col-12">
                    <p className="text-medium mb-2 clr-white">Contact Us</p>
                    <p className="text mb-1 clr-white">WISMA MCIS ZURICH,</p>
                    <p className="text mb-1 clr-white">
                      Tower 1, Level 3A, Jalan Barat,
                    </p>
                    <p className="text mb-4 clr-white">
                      46200, Petaling Jaya, Selangor
                    </p>
                    <div>
                      <a
                        className="text mb-1 clr-white"
                        href="tel:+60176845127"
                      >
                        +60 176845127
                      </a>
                    </div>
                    <div className="mb-4">
                      <a
                        className="text  clr-white"
                        href="mailto:tech@addvaluez.com"
                      >
                        tech@addvaluez.com
                      </a>
                    </div>
                  </div>
                  <Divider style={{ borderBlockStart: "2px solid #3d8ca6" }} />
                  <div className="">
                    <p className="text mb-0 clr-white">
                      © 2023 Addvaluez | All rights reserved
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-1 col-sm-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
