import React from "react";
import classnames from "classnames";
import { Fade } from "react-awesome-reveal";

interface Props {
  type?: string;
}

const AIDevelopment = ({ type = "tab" }: Props) => {
  // Your component code goes here
  return (
    <>
      <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
        <div
          className={classnames({
            "text-center": type === "collapse",
            "ps-5": type !== "collapse",
          })}
        >
          <p className="text f600 clr-secondary mb-2">
            Artificial Intelligence
          </p>
          <p className="text-title mb-4">
            We help businesses leverage the power of artificial intelligence and
            machine learning technologies.
          </p>
          <p className="text mb-5">
            Our team of experienced AI developers specializes in building
            intelligent systems that automate processes, analyze data, and
            generate valuable insights.
          </p>
          <p className="text mb-5">
            We understand that each business has unique requirements and
            challenges. Our AI development services include creating customized
            AI solutions tailored to your specific needs. Whether you need a
            chatbot for customer support, a recommendation system for
            personalized experiences, or a predictive analytics model, we can
            build an AI solution that aligns with your business objectives.
          </p>
        </div>
      </Fade>
    </>
  );
};

export default AIDevelopment;
