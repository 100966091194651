import React from "react";
import Footer from "../Footer/Footer";
import Logo from "../../Assets/Logos/logo.svg";

interface Props {
  children: any;
}

const ContactWrapper = ({ children }: Props) => {
  return (
    <>
      <div className="contact-section">
        <div className="contact-container">{children}</div>
        <footer className="footer bg-primary py-4">
          <div className="">
            <p className="text mb-0 clr-white">
              © 2023 Addvaluez | All rights reserved
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default ContactWrapper;
