import React from "react";
import IconTechStack from "../../../Assets/Icons/future-tech.svg";
import Icon1 from "../../../Assets/Icons/TechStack/icon-nodejs.svg";
import Icon2 from "../../../Assets/Icons/TechStack/icon-typescript.svg";
import Icon3 from "../../../Assets/Icons/TechStack/icon-react.svg";
import Icon4 from "../../../Assets/Icons/TechStack/icon-gql.svg";
import Icon5 from "../../../Assets/Icons/TechStack/icon-next-js.svg";
import Icon6 from "../../../Assets/Icons/TechStack/icon-serverless.svg";
import Icon7 from "../../../Assets/Icons/TechStack/icon-solidity.svg";
import Icon8 from "../../../Assets/Icons/TechStack/icon-etherium.svg";
import Icon9 from "../../../Assets/Icons/TechStack/icon-express.svg";
import { Fade } from "react-awesome-reveal";

// interface Props {
//   // Define the props your component accepts
// }

const icons = [
  { icon: Icon1 },
  { icon: Icon2 },
  { icon: Icon3 },
  { icon: Icon4 },
  { icon: Icon5 },
  { icon: Icon6 },
  { icon: Icon7 },
  { icon: Icon8 },
  { icon: Icon9 },
];

const TechStack = () => {
  // Your component code goes here
  return (
    <>
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center py-4">
            <Fade triggerOnce direction="left" cascade={true} damping={0.2}>
              <img src={IconTechStack} className="m-w-100" />
            </Fade>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12  d-flex align-items-center">
            <Fade triggerOnce direction="right" cascade={true} damping={0.2}>
              <div>
                <p className="text f600 clr-secondary mb-2">TECHNOLOGY STACK</p>
                <p className="text-title mb-4">
                  Invest in future-proof technologies that will remain effective
                  for years to come.
                </p>
                <p className="text mb-4">
                  An efficiently designed software can significantly reduce
                  development time, saving thousands of hours in the process.
                </p>
                <p className="text">
                  We are expertise in technologies with strong community
                  support.
                </p>
                <div className="row">
                  {icons.map((item: any, index: number) => {
                    return (
                      <div className="col-md-4 col-sm-6 col-6 py-4" key={index}>
                        <img
                          src={item.icon}
                          style={{ maxHeight: 100, maxWidth: 200 }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechStack;
