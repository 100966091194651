import React from "react";
import { Button, Card } from "antd";
import { useHistory } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

// interface Props {
//   // Define the props your component accepts
// }

const ContactSales = () => {
  const history = useHistory();
  // Your component code goes here
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-1 col-sm-12"></div>
          <div className="col-md-12 px-0 py-5 col-sm-12">
            <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
              <Card className="bg-grey p-3">
                <div className="row">
                  <div className="col-md-9 col-sm-12 text-md-start text-sm-center text-center">
                    <p className="text-medium mb-0" style={{ maxWidth: 540 }}>
                      {"Ready to transform your business concepts into "}
                      <span className="text-title">software applications?</span>
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-12 d-flex align-items-center justify-content-md-start justify-content-sm-center justify-content-center py-2 text-center">
                    <Button
                      className="btn-primary clr-white"
                      onClick={() => {
                        history.push("/contact");
                      }}
                    >
                      Contact Sales
                    </Button>
                  </div>
                </div>
              </Card>
            </Fade>
          </div>
          <div className="col-md-1 col-sm-12"></div>
        </div>
      </div>
    </>
  );
};

export default ContactSales;
