import React from "react";

import AppWrapper from "../../Components/Wrapper/AppWrapper";
import ContactSales from "./ContactSales/ContactSales";
import Clients from "./Clients/Clients";
import SoftwareDevelopment from "./SoftwareDevelopment/SoftwareDevelopment";
import Solutions from "./Solutions/Solutions";
import SDLifeCycle from "./SDLifeCycle/SDLifeCycle";
import TechStack from "./TechStack/TechStack";
import Home from "./Home/Home";
// interface Props {
//   // Define the props your component accepts
// }

const LandingPage = () => {
  // Your component code goes here
  return (
    <>
      <AppWrapper>
        <div>
          <Home />
          <TechStack />
          <SDLifeCycle />
          <Solutions />
          <SoftwareDevelopment />
          <Clients />
          <ContactSales />
        </div>
      </AppWrapper>
    </>
  );
};

export default LandingPage;
