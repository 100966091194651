import React, { useState } from "react";
import Client1 from "../../../Assets/Images/Client/1.svg";
import { Collapse, CollapseProps, Tabs } from "antd";
import WebDevelopment from "./WebDevelopment";
import MobileDevelopment from "./MobileDevelopment";
import AIDevelopment from "./AIDevelopment";
import BlockchainDevelopment from "./BlockchainDevelopment";
import CloudDevopsDevelopment from "./CloudDevopsDevelopment";
import AI from "../../../Assets/Icons/SoftwareSolutions/ai.svg";
import AIActive from "../../../Assets/Icons/SoftwareSolutions/ai-active.svg";
import BlockChain from "../../../Assets/Icons/SoftwareSolutions/blockchain.svg";
import BlockChainActive from "../../../Assets/Icons/SoftwareSolutions/blockchain-active.svg";
import Cloud from "../../../Assets/Icons/SoftwareSolutions/cloud.svg";
import CloudActive from "../../../Assets/Icons/SoftwareSolutions/cloud-active.svg";
import Mobile from "../../../Assets/Icons/SoftwareSolutions/mobile.svg";
import MobileActive from "../../../Assets/Icons/SoftwareSolutions/mobile-active.svg";
import Website from "../../../Assets/Icons/SoftwareSolutions/website.svg";
import WebsiteActive from "../../../Assets/Icons/SoftwareSolutions/website-active.svg";
import { Fade } from "react-awesome-reveal";
// import { CaretRightOutlined } from '@ant-design/icons';

// interface Props {
//   // Define the props your component accepts
// }

const tabItems = [
  {
    label: "Web Development",
    key: "1",
    icondefault: Website,
    iconactive: WebsiteActive,
    children: <WebDevelopment />,
  },
  {
    label: "Mobile Development",
    key: "2",
    icondefault: Mobile,
    iconactive: MobileActive,
    children: <MobileDevelopment />,
  },
  {
    label: "Blockchain Development",
    key: "3",
    icondefault: BlockChain,
    iconactive: BlockChainActive,
    children: <BlockchainDevelopment />,
  },
  {
    label: "AI Development",
    key: "4",
    icondefault: AI,
    iconactive: AIActive,
    children: <AIDevelopment />,
  },
  {
    label: "Cloud & DevOps",
    key: "5",
    icondefault: Cloud,
    iconactive: CloudActive,
    children: <CloudDevopsDevelopment />,
  },
];

const collapseItems = [
  {
    label: "Web Development",
    key: "1",
    icondefault: Website,
    iconactive: WebsiteActive,
    children: <WebDevelopment type="collapse" />,
  },
  {
    label: "Mobile Development",
    key: "2",
    icondefault: Mobile,
    iconactive: MobileActive,
    children: <MobileDevelopment type="collapse" />,
  },
  {
    label: "Blockchain Development",
    key: "3",
    icondefault: BlockChain,
    iconactive: BlockChainActive,
    children: <BlockchainDevelopment type="collapse" />,
  },
  {
    label: "AI Development",
    key: "4",
    icondefault: AI,
    iconactive: AIActive,
    children: <AIDevelopment type="collapse" />,
  },
  {
    label: "Cloud & DevOps",
    key: "5",
    icondefault: Cloud,
    iconactive: CloudActive,
    children: <CloudDevopsDevelopment type="collapse" />,
  },
];

const getLabel = (selectedTab: string, item: any, i: number) => {
  return (
    <p className="tab-title">
      <span>
        <img
          src={
            Number(selectedTab) - 1 === i ? item.iconactive : item.icondefault
          }
          style={{ width: 20, maxHeight: 24 }}
        />
      </span>
      <span className="text ps-3">{item.label}</span>
    </p>
  );
};

const Solutions = () => {
  const [selectedTab, setSelectedTab] = useState<string>("1");

  // Your component code goes here
  return (
    <>
      <div className="container">
        <div className="row py-5">
          <div className="col-md-12">
            <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
              <div className="text-center">
                <p className="text f600 mb-4 clr-secondary">
                  SOFTWARE SOLUTIONS ON DEMAND
                </p>
                <p className="text-title mb-4">
                  We help bring your breakthrough ideas to life
                </p>
                <div className="pb-5">
                  <p
                    className="text mb-5 margin-auto"
                    style={{ maxWidth: 720 }}
                  >
                    Your company has a distinctive mission and path to growth.
                    Therefore, it is essential to collaborate with a software
                    development partner and devise a strategy to leverage
                    cuttingedge technologies, achieve rapid scalability, and
                    capitalize on emerging opportunities.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-md-12 solutions d-lg-block d-md-none d-none">
            <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
              <Tabs
                defaultActiveKey="1"
                activeKey={selectedTab}
                onChange={(e) => setSelectedTab(e)}
                tabPosition={"left"}
                type="card"
                items={tabItems.map((item: any, i: any) => {
                  return {
                    ...item,
                    label: getLabel(selectedTab, item, i),
                  };
                })}
                style={{ minHeight: 200 }}
              />
            </Fade>
          </div>
          <div className="col-md-12 solutions d-lg-none d-md-block d-block">
            <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <Collapse
                    activeKey={selectedTab}
                    items={collapseItems.map((item: any, i: any) => {
                      return {
                        ...item,
                        label: getLabel(selectedTab, item, i),
                      };
                    })}
                    expandIconPosition="end"
                    accordion
                    onChange={(e) => {
                      setSelectedTab(e[0]);
                    }}
                    defaultActiveKey={"1"}
                  />
                </div>
                <div className="col-1"></div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solutions;
