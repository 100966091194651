import React from "react";
import DevCycle from "../../../Assets/Icons/dev-cycle.svg";
import { Fade } from "react-awesome-reveal";

// interface Props {
//   // Define the props your component accepts
// }

const SDLifeCycle = () => {
  // Your component code goes here
  return (
    <>
      <div className="bg-grey">
        <div className="container">
          <div className="row py-5">
            <div className="col-md-6 col-sm-12 order-md-1 order-sm-2 order-2 d-flex align-items-center">
              <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
                <div>
                  <p className="text f600 clr-secondary mb-2">
                    SOFTWARE DEVELOPMENT
                  </p>
                  <p className="text-title mb-4">
                    A scalable team that handles end-to-end development
                  </p>
                  <p className="text mb-4">
                    Whatever applications you’re building, we’ve got your back
                    at every stage of development to help you rapidly develop
                    high-quality software.
                  </p>
                  <p className="text">
                    Our team of software architects will assist you in creating
                    a software development process that is secure, adaptable,
                    and streamlined to meet your requirements.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="col-md-6 col-sm-12  order-md-2 order-sm-1 order-1 d-flex align-items-center justify-content-center">
              <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
                <img src={DevCycle} className="m-w-100" />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SDLifeCycle;
