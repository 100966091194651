import React, { useState } from "react";

import { Button, Checkbox, Form, Input, notification } from "antd";
import { Fade } from "react-awesome-reveal";
import emailjs from "@emailjs/browser";

// interface Props {
//   // Define the props your component accepts
// }

type FieldType = {
  name?: string;
  emailAddress?: string;
  contactNumber?: string;
  description?: string;
};

const ContactForm = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Your component code goes here

  const onFinish = (values: any) => {
    setIsLoading(true);
    emailjs
      .send("service_mailgun", "template_24ivm5t", values, "fGdHjjWmPcSkNMfsv")
      .then(
        (response) => {
          if (response.status === 200) {
            form.resetFields();
            notification.success({
              message: "Your message has been sent. Thank you!",
            });
          }
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          notification.error({
            message: "Something went wrong!",
          });
        }
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-1 col-sm-1"></div>
        <div className="col-md-10 col-sm-10 col-12">
          <div className="py-5">
            <div className="py-5">
              <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
                <p className="text-title mb-4">What would you like to do?</p>
                <Form
                  name="basic"
                  form={form}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24 }}
                  style={{}}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item<FieldType>
                    name="name"
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <Input placeholder="Your name" style={{ height: 48 }} />
                  </Form.Item>

                  <Form.Item<FieldType>
                    name="emailAddress"
                    rules={[
                      {
                        type: "email",
                        message: "Please input valid email!",
                      },
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your e-mail address"
                      style={{ height: 48 }}
                    />
                  </Form.Item>

                  <Form.Item<FieldType>
                    label=""
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your contact number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your contact number"
                      style={{ height: 48 }}
                    />
                  </Form.Item>

                  <Form.Item<FieldType>
                    label=""
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please input your message!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Tell us about your project"
                      rows={5}
                    />
                  </Form.Item>

                  <Form.Item className="py-4">
                    <Button
                      type="primary"
                      className="btn-primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Send us a message
                    </Button>
                  </Form.Item>
                  <p className="text mb-4">
                    (or) contact us directly at{" "}
                    <a href="mailto:tech@addvaluez.com">tech@addvaluez.com</a>
                  </p>
                </Form>
              </Fade>
            </div>
          </div>
        </div>
        <div className="col-md-1 col-sm-1"></div>
      </div>
    </>
  );
};

export default ContactForm;
