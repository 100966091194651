import React from "react";
import Footer from "../Footer/Footer";
import Logo from "../../Assets/Logos/logo.svg";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

interface Props {
  children: any;
}

const AppWrapper = ({ children }: Props) => {
  const history = useHistory();
  // Your component code goes here
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
        }}
      >
        <div className="container py-5">
          <div className="d-flex justify-content-between">
            <div>
              <img src={Logo} className="app-logo" />
            </div>
            <div>
              <Button
                className="btn-primary clr-white"
                onClick={() => {
                  history.push("/contact");
                }}
              >
                Contact Sales
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="app-container">{children}</div>
      <div className="footer bg-primary">
        <Footer />
      </div>
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <a className="navbar-brand" href="#">
          Sticky Header
        </a>
      </nav>
      <div className="app-container">{children}</div>
      <div className="footer">
        <Footer />
      </div> */}
    </>
  );
};

export default AppWrapper;
