import React from "react";
import classnames from "classnames";
import { Fade } from "react-awesome-reveal";

interface Props {
  type?: string;
}

const MobileDevelopment = ({ type = "tab" }: Props) => {
  // Your component code goes here
  return (
    <>
      <Fade triggerOnce direction="up" cascade={true} damping={0.2}>
        <div
          className={classnames({
            "text-center": type === "collapse",
            "ps-5": type !== "collapse",
          })}
        >
          <p className="text f600 clr-secondary mb-2">
            Mobile Application & Mini Program
          </p>
          <p className="text-title mb-4">
            We help you create cutting-edge and userfriendly applications for
            iOS and Android platforms.
          </p>
          <p className="text mb-5">
            We leverage native mobile app development frameworks like Swift and
            Kotlin to build high-performance and feature-rich applications for
            iOS and Android respectively. By utilizing the native capabilities
            of each platform, we ensure that your app delivers a seamless and
            optimized user experience.
          </p>
          <p className="text mb-5">
            We also offer comprehensive mini program development services to
            help you create powerful and engaging mini applications for
            platforms like Touch’n Go and Alipay. Our team of experienced
            developers specializes in building scalable and innovative mini
            programs that provide unique user experiences.
          </p>
        </div>
      </Fade>
    </>
  );
};

export default MobileDevelopment;
